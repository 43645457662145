<template>
  <b-col class="list_post-col" lg="3" md="5" sm="6" xs="12">
    <div class="header">
      <div class="list flex justify-content-center">
        <span class="list-title">{{ list.title }}</span>
      </div>

      <div class="addPost" v-if="permission.store">
        <b-button class="list-btn" block v-b-modal="`addPost_${list.id}`"
          >+</b-button
        >
        <b-modal
          :ref="`addPostModal_${list.id}`"
          :id="`addPost_${list.id}`"
          centered
          :title="$t('g.addNewPost')"
          @ok="handleOk"
          :ok-title="$t('g.submit')"
          :cancel-title="$t('cancel')"
          :ok-title-html="okTitleHtml"
        >
          <validation-observer :ref="`addPostForm_${list.id}`">
            <b-form @submit.stop.prevent="addPost">
              <b-form-group label-for="title" class="control-input">
                <validation-provider
                  name="title"
                  rules="required"
                  #default="{ errors }"
                >
                  <b-form-input
                    v-model="form_data.title"
                    :state="errors.length > 0 ? false : null"
                    name="title"
                    :placeholder="$t('g.title')"
                  ></b-form-input>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label-for="text" class="control-input">
                <validation-provider
                  name="text"
                  rules="required"
                  #default="{ errors }"
                >
                  <b-form-textarea
                    v-model="form_data.text"
                    :state="errors.length > 0 ? false : null"
                    name="text"
                    :placeholder="$t('g.description')"
                  ></b-form-textarea>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Form File Input -->
              <b-form-group label-for="file" class="control-input">
                <validation-provider name="file" #default="{ errors }">
                  <b-form-file
                    v-model="form_data.media"
                    :state="errors.length > 0 ? false : null"
                    name="media"
                    :placeholder="$t('g.choosePostFile')"
                  ></b-form-file>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </validation-observer>
        </b-modal>
      </div>
    </div>

    <div class="list-post">
      <div class="post" v-for="post in posts" :key="post.id">
        <div class="header">
          <span
            class="title p-0 m-0 cursor-pointer"
            @click="showPost(post.id)"
            >{{ userFullName(post) }}</span
          >
        </div>
        <div
          class="post-content cursor-pointer"
          @click="permission.show && showPost(post.id)"
        >
          <h6 class="d-flex justify-content-between">
            <span>{{ post.title }}</span>
          </h6>
          <img
            v-if="post.media && post.media.length > 0"
            :src="post.media[0].path"
            :alt="post.title"
            class="img-fluid h-100 w-100 bg-cover rounded-lg"
          />
          <span v-else>{{ post.text }}</span>
        </div>
      </div>
    </div>
  </b-col>
</template>

<script>
import {
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { BFormTextarea } from "bootstrap-vue/src/components/form-textarea";
import { required } from "@core/utils/validations/validations";
import toast from "@/libs/toastification";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "ListPosts",
  components: {
    BDropdownItem,
    BDropdown,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
  },
  props: {
    list: {
      type: Object,
      required: true,
    },
    permission: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      data: {
        board_id: "",
        list_id: "",
        post_id: "",
      },
      form_data: {
        title: "",
        text: "",
        media: null,
      },
      posts: [],
      okTitleHtml: "",
      report: {
        show: false,
        reportable_id: null,
        reportable_type: null,
      },
    };
  },
  created() {
    this.data.board_id = this.$route.params.id;
    this.data.list_id = this.list.id;
    this.getPots();
  },
  methods: {
    getPots() {
      this.$http
        .get(
          `/admin/boards/${this.$route.params.id}/lists/${this.list.id}/posts`
        )
        .then((response) => {
          this.posts = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.addPost();
    },
    addPost() {
      this.$refs[`addPostForm_${this.list.id}`].validate().then((success) => {
        if (success) {
          const formData = new FormData();
          formData.append("title", this.form_data.title);
          formData.append("text", this.form_data.text);
          if (this.form_data.media)
            formData.append("media", this.form_data.media);
          this.okTitleHtml =
            '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';

          this.$http
            .post(
              `/admin/boards/${this.$route.params.id}/lists/${this.list.id}/posts`,
              formData
            )
            .then((res) => {
              if ([200, 201].includes(res.status)) {
                this.$refs[`addPostModal_${this.list.id}`].hide();
                this.getPots();
                this.form_data.title = "";
                this.form_data.text = "";
                this.okTitleHtml = "";
                toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Welcome`,
                    icon: "CheckCircleIcon",
                    variant: "success",
                    text: this.$t("g.post_added_successfully"),
                  },
                });
              }
            })
            .catch((error) => {
              toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: this.$t("g.something_went_wrong"),
                },
              });
            });
        }
      });
    },
    userFullName(post) {
      let fullName = "";
      if (post.user) {
        if (post.user.first_name) {
          fullName += post.user.first_name;
        }
        if (post.user.middle_name) {
          fullName += ` ${post.user.middle_name}`;
        }
        if (post.user.last_name) {
          fullName += ` ${post.user.last_name}`;
        }
      } else {
        fullName = post.title;
      }
      return fullName;
    },
    showPost(postId) {
      this.$router.push({
        name: "showPost",
        params: {
          boardId: this.$route.params.id,
          listId: this.list.id,
          postId: postId,
        },
      });
    },
    addReport(id) {
      this.report.reportable_id = id;
      this.report.reportable_type = "post";
      this.$emit("showReport", this.report);
    },
  },
};
</script>

<style lang="scss" src="./_listPosts.scss" />
