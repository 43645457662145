<template>
  <b-col class="list_list-col" lg="3" md="5" sm="6" xs="12">
    <div class="header">
      <div class="list flex justify-content-center cursor-pointer" @click="$bvModal.show(`addList`)">
        <b-button block class="list-btn">+</b-button>
        <b-modal ref="addListModal" id="addList" centered :title="$t('g.show_board/add_list/addNewList')"
                 :ok-title="$t('g.submit')"
                 :cancel-title="$t('cancel')" @ok="handleOk"
          :ok-title-html="okTitleHtml">
          <validation-observer ref="addListForm">
            <b-form @submit.stop.prevent="createList">

              <!-- Form Title Input -->
              <b-form-group label-for="title" class="control-input">
                <validation-provider name="title" rules="required" #default="{ errors }">
                  <b-form-input v-model="form_data.title" :state="errors.length > 0 ? false : null" name="title"
                    :placeholder="$t('g.title')"></b-form-input>
                  <small class="text-danger">{{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Form Description Input -->
              <b-form-group label-for="text" class="control-input">
                <validation-provider name="text" rules="required" #default="{ errors }">
                  <b-form-textarea v-model="form_data.description" :state="errors.length > 0 ? false : null"
                    name="description" :placeholder="$t('g.description')"></b-form-textarea>
                  <small class="text-danger">{{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Is Allow Posting -->
              <b-form-group label-for="text" class="control-input">
                <validation-provider name="text" rules="required" #default="{ errors }">
                  <b-form-checkbox v-model="form_data.is_allow_posting" :state="errors.length > 0 ? false : null"
                    name="is_allow_posting" :placeholder="$t('g.show_board/add_list/is_allow_posting')"
                    class="list-check-box">
                    {{ $t('g.show_board/add_list/is_allow_posting') }}
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            </b-form>
          </validation-observer>
        </b-modal>
      </div>
    </div>
  </b-col>
</template>

<script>
import { BButton, BCol, BForm, BFormCheckbox, BFormFile, BFormGroup, BFormInput, BModal } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { BFormTextarea } from "bootstrap-vue/src/components/form-textarea";
import toast from "@/libs/toastification";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@core/utils/validations/validations";

export default {
  name: "AddList",
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BForm,
    BCol,
    BModal,
    BFormCheckbox,
  },
  data() {
    return {
      form_data: {
        title: '',
        description: '',
        is_allow_posting: 0,
      },
      okTitleHtml: '',
    }
  },
  methods: {
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.createList()
    },
    createList() {
      this.$refs.addListForm.validate()
        .then((success) => {
          if (success) {
            this.$bvModal.hide(`addList`)
            this.okTitleHtml = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
            let formData = new FormData()
            formData.append('title', this.form_data.title)
            formData.append('description', this.form_data.description)
            formData.append('is_allow_posting', this.form_data.is_allow_posting ? 1 : 0)

            this.$http
              .post(`/admin/boards/${this.$route.params.id}/lists`, formData)
              .then((response) => {
                this.okTitleHtml = ''
                this.form_data.title = ''
                this.form_data.description = ''
                this.form_data.is_allow_posting = 0
                this.$emit('createList')
                toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Welcome`,
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: this.$t('g.show_board/add_list/board_list_added_successfully'),
                  },
                })
              })
              .catch((error) => {
                this.okTitleHtml = ''
                this.form_data.title = ''
                this.form_data.description = ''
                this.form_data.is_allow_posting = 0
                toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Error`,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: this.$t('g.something_went_wrong'),
                  },
                })
              })
            this.$emit('createList')
          }
        }).catch((err) => {
          console.log(err)
        })
    },
  }
}
</script>

<style lang="scss" src="./_index.scss"/>
