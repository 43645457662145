<template>
  <content-not-view v-if="!subPermission.boards.show" />
  <div
    class="showBoard flex justify-content-end flex-column w-100 h-100"
    v-else
  >
    <div class="boardHeader">
      <h2 class="courseName">{{ course.name }}</h2>
      <p class="grade">
        {{ $t("g.grade") }} : <span class="pr-1">{{ this.grade }}</span>
      </p>
    </div>

    <b-container fluid class="listsContainer">
      <b-row class="flex-row flex-nowrap" v-if="subPermission.posts.index">
        <ListPosts
          v-for="list in lists"
          :key="list.id"
          :list="list"
          @showReport="showReport"
          :permission="subPermission.posts"
        />
        <report-component
          :reportable-id="reportable_id"
          :reportable-type="reportable_type"
        />
        <AddList @createList="createList" v-if="subPermission.lists.store" />
      </b-row>
      <content-not-view v-else />
    </b-container>
  </div>
</template>

<script>
import ListPosts from "@/components/classrooms/boards/posts/listPosts/ListPosts.vue";
import ReportComponent from "@/components/shared/ReportComponent/index.vue";
import { BCol, BContainer, BRow } from "bootstrap-vue";
import AddList from "@/components/classrooms/boards/addList/index.vue";
import contentNotView from "@/components/contentNotView/contentNotView.vue";

export default {
  name: "ShowBoard",
  components: {
    BCol,
    BRow,
    BContainer,
    AddList,
    ListPosts,
    ReportComponent,
    contentNotView,
  },
  data() {
    return {
      grade: "",
      course: {},
      lists: [],
      reportable_id: null,
      reportable_type: null,
    };
  },
  created() {
    this.getLists();
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSIONS", ["posts", "boards", "lists"]);
      return this.$store.state.userData.sub_Permissions;
    },
  },
  methods: {
    getLists() {
      this.$http
        .get(`/admin/boards/${this.$route.params.id}`)
        .then((response) => {
          this.course = response.data.data.course;
          this.lists = response.data.data.lists;
          this.grade = ![undefined, null].includes(this.course)
            ? ![undefined, null].includes(this.course.edu_years)
              ? this.course.edu_years[0].name
              : ""
            : "";
        })
        .catch((error) => {
          this.$helpers.handleError(error);
        });
    },
    showReport(report) {
      this.reportable_id = report.reportable_id;
      this.reportable_type = report.reportable_type;
      this.$bvModal.show("bv-modal-report");
    },
    createList() {
      this.getLists();
    },
  },
};
</script>

<style lang="scss" src="./_showBoard.scss"></style>
